import styled from 'styled-components';

const HippaIconsStyle = styled.div`
    .commone_icons_text {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        z-index: 1;
        p{
            color: #000;
        }
    }
    .news-ltr-sign {
        margin-top: 10px;
        padding-left: 14px;
    }
    .subscribe-signup {
        margin-top: 10px;
    }
        input.signup-email {
            width: 100%;
            border-radius: 15px;
            border: 2px solid #fff;
            text-align: center;
    }
    button.subs-news-ltr {
        cursor: pointer;
        background:linear-gradient(90deg, #20a4dd 0%, #1ea3b6 24%, #25a07b 100%);
        color: #fff;
        border-radius: 20px;
        width: 100%;
}
`;

export default HippaIconsStyle;