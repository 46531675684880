import { useEffect, useState } from "react";

const VedioPlayer = ({ url, active }) => {
    const [currentUrl, setCurrentUrl] = useState(url);

    useEffect(() => {
        if (active) {
            setCurrentUrl(url);
        } else {
            setCurrentUrl(`${url}&autoplay=0`);
        }
    }, [active, url]);

    return (
        <iframe
            width="1080"
            height="600"
            src={currentUrl}
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );
};

export default VedioPlayer;