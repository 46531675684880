import React, { useState } from 'react';
import _ from 'lodash';
import HippaIconsStyle from './HippaIconSection.style';
import { LinkAnchor } from '../CommonMethods';
import api from '@api/Apis';

function HippaIconSection(props) {
    const [emailData, setEmailData] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [innerLoader, setInnerLoader] = useState(false)
    const ICONS_LINK = [
        { icon: "hippa", url: "https://www.hhs.gov/sites/default/files/privacysummary.pdf" },
        { icon: "clia", url: "https://www.cms.gov/Regulations-and-Guidance/Legislation/CLIA" },
        { icon: "cap", url: "https://www.cap.org/laboratory-improvement/accreditation/laboratory-accreditation-program" },
        // { icon: "gma", url: "https://www.nsf.org/testing/health/nutritional-supplements-personal-care-products/gmp-certification" },
        // { icon: "non_gmo", url: "https://www.nsf.org/testing/health/nutritional-supplements-personal-care-products/gmp-certification" },
        // { icon: "gluten_free", url: "https://www.nsf.org/testing/health/nutritional-supplements-personal-care-products/gmp-certification" }
    ];


    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    async function handleSubmitData() {
        if (!emailData) {
            setErrorMsg('Email is required!');
            setTimeout(() => setErrorMsg(''), 3000);
            return;
        }

        if (!validateEmail(emailData)) {
            setErrorMsg('Please enter a valid email address!');
            setTimeout(() => setErrorMsg(''), 3000);
            return;
        }

        const payload = {
            first_name: "",
            last_name: "",
            newslettercheck: true,
            email: emailData,
            wellness: "Individual",
        };

        try {
            setInnerLoader(true)
            const res = await api.newsletter(payload);
            if (res?.data?.code === 1) {
                setEmailData('');
                setSuccessMsg(res?.data?.message);
                setInnerLoader(false)
                setTimeout(() => {
                    setSuccessMsg('');
                }, 3000);
            } else if (res?.data?.code === 0) {
                setInnerLoader(false)
                setErrorMsg(res?.data?.message);
                setTimeout(() => {
                    setErrorMsg('');
                }, 3000);
            }
        } catch (error) {
            setErrorMsg('Something went wrong. Please try again later.');
            setTimeout(() => setErrorMsg(''), 3000);
            setInnerLoader(false)
        }
    };
    return (
        <HippaIconsStyle>
            <div className='commone_icons_text'>
                <div className='fifth_section_icons'>
                    {_.map(ICONS_LINK, ({ icon, url }, index) => (
                        <LinkAnchor key={icon} href={url} target="_blank">
                            <img src={`/images/${icon}.png`} />
                        </LinkAnchor>
                    ))}
                </div>
                <div className='small_text'>
                    <p className='my-3'>Manufactured in an NSF Certified, cGMP Compliant and FDA Registered facility.</p>
                </div>
                <div className='subscribe-new-ltr d-flex'>
                    <div className='new-sign-newsletter'>
                        <p className='latest-signup'>
                            Stay informed with the latest on community events, cutting-edge health insights, and exclusive resources sign up for the SNIP newsletter today!</p>
                    </div>
                    <div className='news-ltr-sign'>
                        <input type='text' className='signup-email' placeholder='Enter your email address' onChange={(e) => setEmailData(e.target.value)} />
                        <div className='subscribe-signup'>
                            <button type="button" className='subs-news-ltr' onClick={handleSubmitData} >Subscribe Now!</button>
                        </div>
                        {successMsg && <span className='error' style={{ color: 'green', textAlign: "center", fontSize: '18px' }}>{successMsg}</span>}
                        {errorMsg && <span className='error' style={{ color: 'red', textAlign: "center", fontSize: '18px' }}>{errorMsg}</span>}
                    </div>
                </div>
            </div>
            {
                innerLoader === true && (
                    <div className="flexbox">
                        <div className="">
                            <div className="bt-spinner"></div>
                        </div>
                    </div>
                )
            }
        </HippaIconsStyle>
    )
}

HippaIconSection.propTypes = {}

export default HippaIconSection;
